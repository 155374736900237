.aside-popup-wrap {
  background-color: rgba(0, 0, 0, .1);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.aside-popup {
  $base: &;

  /* self */

  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 440px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  /* wrappers */

  &-group {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: revert;

    & > #{$base} {
      position: relative;
      height: 100%;
    }
  }

  /* modificators */

  &--narrow {
    width: 360px;
  }
  &--light-dark {
    background-color: #F6F6F6;
  }

  /* inners */

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px ($aside-popup-gutter);
    flex: 0 0 auto;

    &--white {
      background-color: #fff;
    }

    h2 {
      color: #292929;
      font-size: 28px;
      font-weight: bold;
      position: relative;
      padding-right: 40px;

      & .o-wash-status-icon {
        position: absolute;
        right: 0;
        top: 3px;
      }
    }

    &__close {
      color: #B4B4B4;
      font-size: 14px;
      line-height: 1;
      background: url("../img/icons/close-gray.svg") right center no-repeat;
      padding: 0 25px 0 15px;
      cursor: pointer;

      &:hover {
        background-image: url("../img/icons/close-red.svg");
      }
    }
  }

  &__foot {
    padding: 0 ($aside-popup-gutter);
    margin-bottom: 15px;
    margin-top: auto;
  }

  &__body {
    padding: 0 ($aside-popup-gutter);
    margin-bottom: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}