$col_time_width: 60px;
$col_user_width: 150px;
$col_car_width: 120px;
$col_price_width: 80px;
$col_label_width: 285px;
$col_status_width: 40px;
$col_services_width: calc(100% - #{$col_time_width} - #{$col_user_width} - #{$col_car_width} - #{$col_price_width} - #{$col_label_width} - #{$col_status_width});

.o-table {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0 1px 1px rgba(180, 180, 180, 0.6);
  margin-bottom: 25px;

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #EAEAEA;
    }

    &:hover {
      background-color: #F6F6F6;
    }
  }

  &__col {
    padding: 13px 18px;
    font-size: 12px;
    line-height: 1.4;
    flex: 0 0 auto;

    &--time {
      padding: 13px;
      background-color: #E8EFF5;
      width: $col_time_width;

      & > div {
        display: block;
      }
    }

    &--user, &--car, &--service, &--price {
      & > div {
        display: block;

        &:first-child {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    &--user {
      width: $col_user_width;
      word-wrap: break-word;
    }

    &--car {
      width: $col_car_width;
    }

    &--price {
      width: $col_price_width;
    }

    &--service {
      width: $col_services_width;
      min-width: 200px;
    }

    &--label {
      padding-left: 12px;
      padding-right: 12px;
      width: $col_label_width;

      & > .label {
        margin: 0 5px;
        min-width: 120px;
      }
    }

    &--status {
      padding-left: 0;
      padding-top: 15px;
      width: $col_status_width;
    }
  }
}