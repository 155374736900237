.add-box-times {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.add-box-times__item {
  display: flex;
  padding: 0 10px;
  align-self: center;

  & > div {
    width: 100px;
    min-width: auto;
  }
}
