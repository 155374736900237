$popupPadding: 40px;

.popup-wrap {
  background-color: rgba(0, 0, 0, .1);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.popup {
  z-index: 100;
  background: #FFFFFF;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 630px;
  width: 100%;
  padding: $popupPadding;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 1.1;
    text-align: center;
    padding: 20px;

    &--head {
      background: #FAFAFA;
      border-radius: 8px 8px 0 0;
      margin: (-$popupPadding) (-$popupPadding) $popupPadding (-$popupPadding);
    }
  }

  &--success,
  &--error {
    max-width: 450px;
  }

  &__ok {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url("../img/icons/success-black.svg") center no-repeat #EAEAEA;
    background-size: 17px 13px;
  }

  &__error {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url("../img/icons/error-white.svg") center no-repeat #ea3b30;
    background-size: 10px 23px;
  }

  &__enter-in {
    text-align: center;
    font-size: 14px;
    color: #818181;
  }
}