@import "reset.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto');

$container-md: 1020px;
$container-xl: 1293px;

$gutter-xl: 20px;
$gutter-md: 10px;

$aside-popup-gutter: 20px;

$header-height: 50px;
$button-height: 46px;

$black: #444444;
$dark-black: #222222;
$red: #fa2424;
$gray: #707070;
$white: #ffffff;

$disabled_opacity: 0.2;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: $black;
}

.container {
  max-width: $container-md;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.w100 {
  width: 100%;
}

.fs12 {
  font-size: 12px;
}
.fs19 {
  font-size: 19px;
}

.crossed {
  text-decoration: line-through;
}

.acenter {
  text-align: center;
}

.clear {
  clear: both;
}

.bold {
  font-weight: bold;
}
.nowrap {
  white-space: nowrap;
}
.c-dark-black {
  color: $dark-black;
}
.c-gray {
  color: $gray;
}