$time-item-height: 30px;
.o-calendar {
  background: #FFFFFF;
  box-shadow: 0 1px 1px rgba(180, 180, 180, 0.6);
  border-radius: 4px;
  padding: 15px 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__scale {
    height: 100%;
    width: 30px;
    margin-right: 15px;
    border: 1px solid #fff;
    padding-top: 19px;

    &__item {
      height: $time-item-height;
      position: relative;
      color: #292929;
      font-size: 9px;
      text-align: right;

      &:nth-child(even) {
        color: #B4B4B4;
      }
    }
  }

  &__boxes {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  &__box {
    position: relative;
    width: 215px;
    margin-right: 10px;
    vertical-align: top;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;

    &__items {
      position: relative;
    }

    &__title {
      color: #292929;
      line-height: 16px;
      font-size: 12px;
      height: 26px;
      flex: 0 0 auto;
    }

    &__delete {
      position: absolute;
      top: -0px;
      right: 0;
      border: none;
      padding: 0 20px 0 0;
      margin: 0;
      color: #707070;
      font-family: 'Roboto', sans-serif;
      font-size: 11px;
      line-height: 18px;
      height: 18px;
      cursor: pointer;
      background: url("../img/icons/trash-yellow.svg") right center no-repeat transparent;
      transition: opacity 300ms ease;

      &:disabled {
        opacity: 0.5;
      }
    }

    &__add {
      position: relative;
      border: 1px solid #EAEAEA;
      flex: 1 0 auto;
      text-align: center;
      padding-top: 65px;

      & button {
        color: #B4B4B4;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        border: none;
        background: none;
        text-align: center;
        cursor: pointer;
        padding: 0;
        margin: 0;
        transition: opacity 300ms ease;

        &:before {
          display: block;
          content: '';
          width: 50px;
          height: 50px;
          background: url("../img/icons/plus-gray.svg") center no-repeat #fff;
          background-size: 14px;
          border: 1px solid #E0E0E0;
          border-radius: 50%;
          margin: 0 auto 10px;
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    &__timeline {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #F55050;
      z-index: 10;
    }

    &__item {
      height: $time-item-height;
      width: 100%;
      border: 1px solid #EAEAEA;
      background-color: #E8EFF5;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      transition: border ease-in-out 200ms;
      color: #292929;

      &--not-confirmed {
        background-color: #F7C449;
      }

      &:hover {
        z-index: 11;
        border: 1px solid #B4B4B4;
      }

      &:before {
        content: '';
        position: absolute;
        border-top: 1px solid #fff;
        top: -2px;
        left: 1px;
        right: 1px;
      }

      &__inner {
        height: 100%;
        border: 1px solid #fff;
        padding: 5px 7px;
      }

      &__car-number {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      &__car-mark {
        font-size: 12px;
      }

      &__times {
        position: absolute;
        top: 9px;
        right: 13px;
        font-size: 11px;
      }

      &--small {
        & .o-calendar__box__item__car-mark {
          display: none;
        }
      }

      &__point {
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: #0083C1;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }

    &__dummy {
      height: $time-item-height;
      width: 100%;
      border: 1px solid #EAEAEA;
      background-color: #fff;
      z-index: 1;
      cursor: pointer;

      &__inner {
        display: none;
        height: 100%;
        border: 1px solid #fff;
        padding: 5px 10px;
        color: #292929;
      }

      &:not(:first-child) {
        border-top: none;
      }

      &:nth-child(even) {
        border-bottom-style: dashed;
      }

      &:not(&--disabled):hover {
        position: relative;
        z-index: 13;
        & .o-calendar__box__dummy__inner {
          display: block;
          background: #F7C449;
        }
      }

      &--disabled {
        cursor: default;
      }

      &--not-working {
        background: #ffe4e1;
        border: transparent solid !important;
      }
    }
  }
}
