
.o-wash-status-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;

  &--points {
    background-image: url("../img/icons/3points-gray.svg");
    background-size: 17px 5px;
  }
  &--success {
    background-image: url("../img/icons/success-green.svg");
    background-size: 17.6px 13.4px;
  }
  &--car {
    background-image: url("../img/icons/car-green.svg");
    background-size: 18px 16px;
  }
}

.o-prop {
  &-group & {
    & {
      margin-bottom: 10px;
    }
  }

  position: relative;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 0;
  min-height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &--hovered:not(&--disabled):hover {
    border-color: #b6b6b6;
    cursor: pointer;
  }
  &--error {
    border-color: #F55050 !important;
    box-shadow: inset 0 0 1px 1px #F55050 !important;
  }
  &--hasError {
    margin-bottom: 25px !important;
  }
  &--selected {
    border-color: #F7C449 !important;
    box-shadow: inset 0 0 1px 1px #F7C449 !important;
  }

  &--disabled {
    opacity: $disabled_opacity;
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin: 0 11px;
    flex: 0 0 auto;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  &__body {
    margin: 0 11px;
    font-size: 14px;
    line-height: 1.3;
    color: #292929;
  }

  &__icon ~ & {
    &__body {
      margin-left: 0;
    }
  }
  &__body ~ & {
    &__icon {
      margin-left: auto;
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: -20px;
    color: #F55050;
    font-size: 12px;
  }
}

.o-comment-textarea {
  background: #fff;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  min-height: 56px;

  font-family: Roboto, sans-serif;
  font-size: 14px;

  &--empty {
    background: #F6F6F6;
    color: #B4B4B4;
  }

  &::placeholder {
    color: #B4B4B4;
  }

  &:disabled {
    opacity: $disabled_opacity;
  }
}

.o-summary {
  &__item {
    border-bottom: 1px solid #f0f0f0;
    padding: 9px 0;
    color: #828282;
    font-size: 14px;
    line-height: 1.3;

    display: flex;

    /*&:last-child {
      border-bottom-color: transparent;
    }*/

    & > * {
      margin-left: 10px;
      margin-right: 10px;
    }
    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
}

.o-status-group {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  & > .label {
    width: 50%;
    margin: 0 10px;
  }
}

.o-field {
  $base: &;

  /* wrappers */

  &-list {
    & > #{$base} {
      margin-bottom: 25px;
    }
  }

  /* self */

  /* modificators */

  &--disabled {
    opacity: $disabled_opacity;
  }

  /* inners */

  &__label {
    color: #707070;
    font-size: 12px;
    margin-bottom: 5px;
  }
  &__input {
    border: none;
    background: #fff;
    border-bottom: 1px solid #EAEAEA;
    color: #292929;
    width: 100%;
    padding: 5px 1px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    
    &:focus {
      border-bottom-color: #B4B4B4;
    }

    &::placeholder {
      color: #B4B4B4;
    }

    &:disabled {
      opacity: $disabled_opacity;
    }
  }

  &-duration {
    display: flex;
    flex-direction: row;
    align-items: center;

    #{$base}__input {
      width: 50px;
      flex: 0 1 auto;
    }

    &__edit-toggle {
      flex: 0 0 auto;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-size: 15px;
      background-image: url("../img/icons/pen-green.svg");
      background-repeat: no-repeat;
      background-position: center;

      &.on {
        background-size: 22px;
        background-image: url("../img/icons/function-yellow.svg");
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.o-car-category {
  $base: &;

  /* wrappers */

  &-group {
    margin-left: -4px;
    margin-right: -4px;

    & > #{$base} {
      float: left;
      width: calc((100% / 4) - 8px);
      margin: 4px;
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }

  /* self */

  border-radius: 6px;
  border: 1px solid #EAEAEA;
  min-height: 70px;
  background: #fff;
  text-align: center;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  /* modificators */

  &:not(&--disabled):hover {
    border-color: #B4B4B4;
  }

  &--selected {
    border-color: #F7C449;
    box-shadow: inset 0 0 1px 1px #F7C449 !important;
  }

  &--disabled {
    opacity: $disabled_opacity;
    cursor: default;
  }

  /* inners */

  &__icon {
    width: 100%;
    margin-top: auto;
  }

  &__title {
    color: #292929;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 3px;
  }
}

.o-service {
  $base: &;
  $card: '-card';

  &-group {
    & {
      margin-bottom: 30px;
    }

    & > #{$base}#{$card} {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 17px;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  &#{$card} {
    /* self */

    position: relative;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 15px 20px 15px 70px;
    cursor: pointer;

    /* inners */

    &:after {
      display: block;
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -15px;
      content: '';
      width: 30px;
      height: 30px;
      background: url("../img/icons/unchecked-yellow.svg") center no-repeat;
      background-size: 30px;
    }

    &--selected:after {
      background-image: url("../img/icons/checked-yellow.svg");
    }

    &__title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__price {
      color: #707070;
      font-size: 14px;
    }

    &__time {
      float: right;
      display: inline-block;
      color: #B4B4B4;
      font-size: 12px;
      padding-left: 20px;
      background: url("../img/icons/time-yellow.svg") 0 center no-repeat;
      background-size: 12px;
    }
  }
}

.o-date-time-select {
  display: flex;
  height: 100%;
  flex-direction: column;

  .o-time-select {
    height: 100%;
    overflow-y: auto;
    margin: 0 (-$aside-popup-gutter);
    padding: 20px ($aside-popup-gutter) 0;
  }
}

.o-date-select {
  margin: 0 (-$aside-popup-gutter);
  border-bottom: 1px solid rgba(180, 180, 180, 0.6);
  background-color: #fff;

  & .DayPicker {
    width: 100% !important;
  }
  & .DayPicker-Month {
    margin: 0 !important;
  }
  & .DayPicker-months-tab {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  & .DayPicker-Day,
  & .DayPicker-Weekday {
    margin: 3px 5px !important;
  }
}

.o-time-select {
  &__title {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;

    &__time {
      display: inline-block;
      color: #B4B4B4;
      font-size: 12px;
      font-weight: normal;
      margin-left: 15px;
      padding-left: 20px;
      background: url("../img/icons/time-yellow.svg") 0 center no-repeat;
      background-size: 12px;
    }
  }

  &__item {
    position: relative;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 30px 8px 10px;
    color: #292929;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 4px;

    &:after {
      display: block;
      position: absolute;
      content: '';
      right: 11px;
      top: 50%;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      background: url("../img/icons/radio-unchecked-yellow.svg") center no-repeat;
      background-size: 16px;
    }

    &--selected:after {
      background-image: url("../img/icons/radio-checked-yellow.svg");
    }
  }
}