.signin-form {
  &__inp-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -20px;

    & > * {
      flex: 1;
      margin: 0 20px;
    }
  }
  &__inp {
    & label {
      font-size: 12px;
      color: #B4B4B4;
      display: block;
    }

    & input {
      border: 0;
      border-bottom: 1px solid #EAEAEA;
      color: $black;
      font-size: 16px;
      line-height: 24px;
      width: 100%;

      &:focus {
        border-color: #F7C449;
      }
    }

    &--success {
      & input {
        border-color: #B4B4B4 !important;
        background: url("../img/icons/success-gray.svg") center right no-repeat;
        background-size: 17px 13px;
      }
    }

    &--error {
      & input {
        border-color: #F55050 !important;
        background: url("../img/icons/close-red.svg") center right no-repeat;
        background-size: 14px;
      }
    }
  }
  &__button-wrap {
    margin-top: 30px;
    text-align: center;
  }
  &__button {
    font-weight: bold;
    line-height: 44px;
    font-size: 17px;
    color: #292929;
    border: 1px solid #F7C449;
    background: #F7C449;
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.6);
    border-radius: 4px;
    text-align: center;
    max-width: 320px;
    width: 100%;
    cursor: pointer;

    &-cancel {
      border: 1px solid #B4B4B4;
      background: #B4B4B4;
    }

    &:hover {
      border: 1px solid #B4B4B4;
    }

    &:disabled {
      opacity: 0.2;
    }
  }
}
