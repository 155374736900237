.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
  background: #fff;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 $gutter-md;

  &__inner {
    display: flex;
    flex-direction: row;
    max-width: $container-md;
    height: $header-height;
    margin: 0 auto;

    & > * {
      margin: auto auto;
      width: calc(100% / 3);
    }
    &__left {
    }
    &__center {
      text-align: center;
    }
    &__right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__search {
    background: url("../img/icons/search.svg") 0 center no-repeat #fff;
    background-size: 17px;
    padding: 0 40px 0 30px;
    width: 100%;
    max-width: 200px;
    position: relative;

    & input {
      color: #707070;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      border: none;
      width: 100%;
    }

    &__clear {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      background: url("../img/icons/clear_search.svg") center no-repeat;
      background-size: 30px;
      cursor: pointer;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;

    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 15px;
    min-width: 136px;

    font-weight: normal;
    line-height: 38px;
    font-size: 16px;

    color: #444444;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      border: 1px solid #F7C449;
    }

    & > * {
      margin: auto;
    }

    &--user {
      &:after {
        content: '';
        background: url("../img/icons/user-gray.svg") center center no-repeat;
        background-size: 16px;
        width: 16px;
        height: 30px;
        margin: auto 0;
      }
    }

    &--logout {
      background-color: #F7C449;

      &:after {
        content: '';
        background: url("../img/icons/array-right-white.svg") center center no-repeat;
        background-size: 16px;
        width: 16px;
        height: 30px;
        margin: auto 0;
      }
    }

    &-logout {
      display: none;
      position: absolute;
      padding-top: 5px;
    }
    &__user-btns {
      &:hover {
        .header__btn-logout {
          display: block;
        }
      }
    }
  }

  &__logo {
    height: 30px;
    & img {
      height: 100%;
    }
  }
}