.revenue_plan {
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(180, 180, 180, 0.6);
  border-radius: 4px;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 65px;
  margin-bottom: 40px;

  &__stat {
    padding: 10px 15px;
    border-radius: 3px;
    min-width: 165px;

    &--gray {
      background-color: #E8EFF5;
    }

    &--yellow {
      background-color: #F7C449;
    }

    &__name {
      font-size: 12px;
      color: #707070;
      margin-bottom: 5px;

      span {
        color: #000000;
      }
    }

    &__value {
      font-size: 16px;
      color: #000000;
    }
  }
}
