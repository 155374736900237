%icon {
  content: '';
  margin: 0 5px 0 10px;
  height: $button-height;
  background-position: center;
  background-repeat: no-repeat;
}

.react-select {
  &-container {
    display: inline-block;
    vertical-align: middle;
    min-width: 300px;


    &--icon-pin {
      .react-select__control:before {
        @extend %icon;
        width: 12px;
        background-image: url("../img/icons/pin-gray.svg");
        background-size: 12px 19px;
      }
    }
    &--icon-calendar {
      .react-select__control:before {
        @extend %icon;
        width: 18px;
        background-image: url("../img/icons/calendar-gray.svg");
        background-size: 18px 20px;
      }
    }
  }

  &__control {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.6);
    border-radius: 4px;
    height: $button-height;
    font-size: 16px;
    color: #444444;
    padding: 0 5px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;

    &--is-focused,
    &--menu-is-open,
    &:hover {
      border: 1px solid #B4B4B4;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    &--is-focused {
      background-color: #e8eff5 !important;
    }
    &--is-selected {
      background-color: #f7c449 !important;
    }
  }

  &__menu {
    z-index: 10002 !important;
  }
}